var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-table',{ref:"cpTable",attrs:{"get-data-action":"operators/getOperatorsList","filters":_vm.filters,"fields":_vm.tableFields,"search-value":_vm.searchQueryParam,"data-parser":_vm.convertedInitList},on:{"onSort":function($event){return _vm.$emit(_vm.onSort, $event)}},scopedSlots:_vm._u([{key:"authorizationLevel",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{attrs:{"variant":"outline-info"}},[_vm._v(" "+_vm._s(rowData.item.authorizationLevel)+" ")])]}},{key:"organization",fn:function(ref){
var rowData = ref.rowData;
return (_vm.isMegaadmin)?[_vm._v(" "+_vm._s(rowData.item.organization)+" ")]:undefined}},{key:"enabled",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{attrs:{"variant":rowData.item.enabled ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(_vm._f("activeInactive")(rowData.item.enabled))+" ")])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-btn',{attrs:{"variant":"default edit-operator-btn btn-xs md-btn-flat","title":_vm.$t('common.button.edit'),"disabled":!_vm.canEditOperator(rowData.item)},on:{"click":function($event){return _vm.$emit('onEdit', rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create",style:({ color: '#04bec4' })}),_vm._v(" "+_vm._s(_vm.$t("common.button.edit"))+" ")]),(_vm.canRedirectHSM())?_c('b-btn',{attrs:{"variant":"default btn-xs md-btn-flat","title":_vm.$t('operators.button.operators.hsm')},on:{"click":function($event){return _vm.$emit('onHSM', rowData.item)}}},[_c('i',{staticClass:"ion ion-md-lock",style:({ color: '#04bec4' })}),_vm._v(" "+_vm._s(_vm.$t("operators.button.operators.hsm"))+" ")]):_vm._e(),(_vm.canResetAuthentication(rowData.item))?_c('b-btn',{attrs:{"disabled":_vm.disableEditOperator || _vm.operatorData.email === rowData.item.email,"variant":"default btn-xs md-btn-flat","title":_vm.$t('operators.button.operators.reset')},on:{"click":function($event){return _vm.$emit('onReset', rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create",style:({ color: '#04bec4' })}),_vm._v(" "+_vm._s(_vm.$t("operators.button.operators.reset"))+" ")]):_vm._e(),(_vm.canEnableDisableOperator(rowData.item))?_c('b-btn',{attrs:{"variant":"default btn-xs md-btn-flat","title":_vm.getActivationStatus(rowData.item.enabled),"disabled":_vm.operatorData.email === rowData.item.email},on:{"click":function($event){return _vm.$emit('onChangeStatus', rowData.item)}}},[_c('i',{staticClass:"ion ion-md-switch"}),_vm._v(" "+_vm._s(_vm.getActivationStatus(rowData.item.enabled))+" ")]):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }